import { DoctorMotivatorFormSubmission } from '@/modules/DoctorMotivatorCallToAction/DoctorMotivatorCallToAction';
import { BookAppointmentFormValues } from '@/modules/UserDashboard/UserDashboardFF_DOT144/components/BookAppointmentForm/BookAppointmentForm';
import {
  IGrade,
  IRootSpecialityV2,
  ISpeciality,
  ISubSpeciality,
  JobGrade,
  JobRootSpecialtyV2,
  KeyValue,
} from '@/types/jobs';
import { Referee, Resume } from '@/types/profile';
import { OnboardingInformation, RegistrationInformation } from '@/types/registration';
import { MakeRequestResponse } from '@/types/useRequestTypes';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { tryToNoticeClientError } from './clientErrorInfo';

export const apiRoute = '/api';

class UseRequestError extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UseRequestError);
    }
    this.name = 'useRequest Error';
  }
}

const noticeError = (err: AxiosError) => {
  try {
    if (typeof window === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { tryToNoticeError } = require('./errorInfo');

      if (err.response) {
        let email;

        if (err.response.config) {
          try {
            const parsedData = err.response.config.data ? JSON.parse(err.response.config.data) : {};
            email = parsedData.username || err.response.config.params?.email;
          } catch (parseError) {
            console.error('Error in parsing data ', parseError);
          }
        }

        const message = `Status Code: '${err.response.status}' Status Text: '${err.response.statusText}'  Email Id: '${email}' Url: '${err.response.config?.url}'`;
        tryToNoticeError(new UseRequestError(message), {
          errorPlace: 'useRequest call',
        });
      } else {
        tryToNoticeError(new UseRequestError(err), {
          errorPlace: 'useRequest call',
        });
      }
    } else {
      tryToNoticeClientError(err);
    }
  } catch (sentryErr) {
    console.error('There was an error trying to send to Sentry: ', sentryErr);
  }
};

/**
 * Make a request to a Medrecruit API via the /api page proxy
 * Internally reports errors without any helpful context; avoid using in new code
 * @description: makeRequestAndNoticeErrors({url: 'XXXXX', data: {}})
 * @return { response, error }
 */
export const makeRequestAndNoticeErrors = async <T>(
  axiosConfig: AxiosRequestConfig,
): Promise<MakeRequestResponse<T>> => {
  try {
    return { response: await axios(axiosConfig) };
  } catch (err) {
    noticeError(err);
    return { error: err.response?.data || err };
  }
};

/**
 * Make a request to a Medrecruit API via the /api page proxy
 * Does _not_ report any errors; consumers should do this if appropriate
 * @description: makeRequestAndNoticeErrors({url: 'XXXXX', data: {}})
 * @return { response, error }
 */
export const makeRequest = async <T>(
  axiosConfig: AxiosRequestConfig,
): Promise<MakeRequestResponse<T>> => {
  try {
    return { response: await axios(axiosConfig) };
  } catch (err) {
    return { error: err.response?.data || err };
  }
};

/**
 * Make a request to some API that is not the medrecruit backend
 * // TODO allow typing and conversion of response and error
 * @return { response, error }
 */
export const makeExternalRequest = async (axiosConfig: AxiosRequestConfig) => {
  let response;
  let error;
  try {
    response = await axios(axiosConfig);
  } catch (err) {
    noticeError(err);
    error = err.response?.data || err;
  }
  return { response, error };
};

export const isUserExisting = email =>
  makeRequest<boolean>({
    method: 'post',
    url: `${apiRoute}/users/check-user-exists`,
    data: { email },
  });

export const sendBasicInfo = data =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/users/reserve`,
    data,
  });

export const submitRegister = (data: RegistrationInformation) =>
  makeRequestAndNoticeErrors<{ userId: string }>({
    method: 'post',
    url: `${apiRoute}/users/register`,
    data,
  });

export const resendEmail = email =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/users/send-email-verification`,
    data: { email },
  });

export const uploadCV = data =>
  makeRequestAndNoticeErrors<{
    updatedDate?: Date;
  }>({
    method: 'post',
    url: `${apiRoute}/users/resume`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const changePassword = data =>
  makeExternalRequest({
    method: 'post',
    url: `${apiRoute}/change-password`,
    data,
  });

export const addFavoriteJob = id =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/jobs/${id}/favourite`,
  });

export const deleteFavoriteJob = id =>
  makeRequestAndNoticeErrors({
    method: 'delete',
    url: `${apiRoute}/jobs/${id}/favourite`,
  });

export const saveAlerts = data =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/alerts`,
    data,
  });

export const deleteAlerts = id =>
  makeRequestAndNoticeErrors({
    method: 'delete',
    url: `${apiRoute}/alerts/${id}`,
  });

export const updateAlerts = (id, data) =>
  makeRequestAndNoticeErrors({
    method: 'put',
    url: `${apiRoute}/alerts/${id}`,
    data,
  });

/**
 * Return the Grades that users can be associated with, via registration and their profile
 */
export const getUserGrades = (): Promise<MakeRequestResponse<Array<IGrade>>> =>
  makeRequestAndNoticeErrors<Array<IGrade>>({
    url: `${apiRoute}/picklist/grades`,
  });

/**
 * Return the Grades that jobs can be associated with
 */
export const getJobGrades = async (): Promise<Array<IGrade>> => {
  const result = await makeRequestAndNoticeErrors<Array<IGrade>>({
    url: `${apiRoute}/picklist/grades`,
  });
  if ('response' in result) {
    return result.response.data.filter(grade => grade.key !== 'Medical Student');
  }
  throw result.error;
};

export const getSpecialities = (
  grade: JobGrade,
): Promise<MakeRequestResponse<Array<ISpeciality>>> =>
  makeRequestAndNoticeErrors<Array<ISpeciality>>({
    url: `${apiRoute}/picklist/${encodeURIComponent(grade)}/specialities`,
  });

// V2 grade | speciality | subspeciality functions

/**
 * Get's the job grades from the v2 endpoint
 *
 * @returns {Promise<Array<IGrade>>} - The job grades
 */
export const getJobGradesV2 = async (): Promise<Array<IGrade>> => {
  const result = await makeRequestAndNoticeErrors<Array<IGrade>>({
    url: `${apiRoute}/v2/picklist/grades`,
  });
  if ('response' in result) {
    return result.response.data.filter(grade => grade.key !== 'Medical Student');
  }
  throw result.error;
};

/**
 * Get's the root specialities based on job grade from the v2 endpoint
 *
 * @param {JobGrade} grade - The job grade
 * @returns {Promise<Array<IRootSpecialityV2>>} - The root specialities available for the job grade
 */
export const getRootSpecialtiesV2 = (
  grade: JobGrade,
): Promise<MakeRequestResponse<Array<IRootSpecialityV2>>> =>
  makeRequestAndNoticeErrors<Array<IRootSpecialityV2>>({
    url: `${apiRoute}/v2/picklist/grades/${encodeURIComponent(grade)}/specialties`,
  });

/**
 * Get's the sub specialities based on job grade and root speciality from the v2 endpoint
 *
 * @param {JobRootSpecialtyV2} rootSpeciality - The job specialty
 * @returns {Promise<Array<ISubSpeciality>>} - The sub specialities available for the given specialty
 */
export const getSubSpecialitiesV2 = (
  grade: JobGrade,
  rootSpeciality: JobRootSpecialtyV2,
): Promise<MakeRequestResponse<Array<ISubSpeciality>>> =>
  makeRequestAndNoticeErrors<Array<ISubSpeciality>>({
    url: `${apiRoute}/v2/picklist/grades/${encodeURIComponent(
      grade,
    )}/specialties/${encodeURIComponent(rootSpeciality)}/subspecialties`,
  });

export const getPreferredWorkLocations = () =>
  makeRequestAndNoticeErrors<Array<KeyValue>>({
    url: `${apiRoute}/picklist/preferred-work-locations`,
  });

export const getRegisterOrgData = () =>
  makeRequestAndNoticeErrors<Array<KeyValue>>({
    url: `${apiRoute}/picklist/registration-org`,
  });

export const getImportantGoals = (): Promise<MakeRequestResponse<Array<KeyValue>>> =>
  makeRequestAndNoticeErrors<Array<KeyValue>>({
    url: `${apiRoute}/picklist/important-goals`,
  });

export const submitProfile = data =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/profile`,
    data,
  });

export const withdrawJobApplication = (jobId: string) =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/jobs/${jobId}/withdraw`,
  });

export const submitReferees = data =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/referees`,
    data,
  });

export const getReferees = () =>
  makeRequestAndNoticeErrors<Array<Referee>>({
    url: `${apiRoute}/referees`,
  });

export const submitApply = (id, data) =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/jobs/${id}/apply`,
    data,
  });

export const submitEnquiry = async (jobNumber: string) => {
  const result = await makeRequest<{ status: string }>({
    method: 'POST',
    url: `${apiRoute}/jobs/${jobNumber}/apply`,
  });
  if ('response' in result) {
    return { status: result.response.data?.status };
  }
  throw new Error(`Error while enquiring for the job. Cause '${result.error?.message}'`);
};

/**
 * Request that the named whitepaper is delivered to the current logged in user
 * @param paperName the name (rather than slug) of a whitepaper
 */
export const requestWhitepaperSending = (paperName: string) =>
  makeRequestAndNoticeErrors<{ isExistingUser: boolean }>({
    method: 'post',
    url: `${apiRoute}/whitepapers/${paperName}`,
  });

export const submitDeactivation = () =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/users/deactivate`,
  });

export const updateTermsVersion = newVersion => {
  return makeRequestAndNoticeErrors({
    method: 'put',
    url: `${apiRoute}/accept-latest-terms`,
    data: { newVersion },
  });
};

export const claimRewards = () =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/users/claim-rewards`,
  });

export const getResumeInfo = () =>
  makeRequestAndNoticeErrors<Resume>({
    url: `${apiRoute}/users/resume`,
  });

export const postLeadforms = data =>
  makeRequestAndNoticeErrors({
    method: 'post',
    url: `${apiRoute}/leadforms`,
    data,
  });

/**
 * Submits a user's answers to a set of motivation questions that are found on the dashboard
 */
export const submitMotivatorQuestions = async (data: DoctorMotivatorFormSubmission) => {
  const result = await makeRequest({
    method: 'POST',
    url: `${apiRoute}/users/motivators/type/MotivatorsV1`,
    data,
  });
  if ('response' in result && result.response.status === 201) {
    return result.response.data;
  }
  throw new Error('Error while submitting motivator questions');
};

/**
 * Submits a request to a Solution Specialist for an appointment on a certain day at a specified time
 */
export const submitBookAppointment = async (data: BookAppointmentFormValues) => {
  const result = await makeRequest({
    method: 'POST',
    url: `${apiRoute}/users/book-appointment`,
    data,
  });
  if ('response' in result && result.response.status === 202) {
    return result.response.data;
  } else if ('error' in result) {
    throw new Error('Error while submitting request for appointment', result.error as Error);
  }
  throw new Error('Error while reading api response after submitting request for appointment');
};

/**
 * Updates the user session after the user is created in Medrecruit
 */
export const updateUserSession = async (): Promise<{ status: string }> => {
  const result = await makeRequest<{ status: string }>({
    method: 'get',
    url: `${apiRoute}/update-user-session`,
  });
  if ('response' in result && result.response.status === 200) {
    return result.response.data;
  }
  // FIXME error handling
  throw new Error('Error while updating user session');
};

export const submitOnboarding = (data: OnboardingInformation) =>
  makeRequest({
    method: 'post',
    url: `${apiRoute}/users/onboarding`,
    data,
  });
